<!--
 * @Author: zl
 * @Date: 2022-03-24 09:06:54
 * @LastEditTime: 2023-01-13 14:37:04
 * @LastEditors: zl
 * @Description: 区域板
-->
<template>
	<div class="select-area-layout">
		<div class="select-box">
			<div class="header">
				<div class="left"
					 @click="jumpMap">
					<p>合肥东部新中心项目管理系统</p>
					<p>New Eastern Center Project Mangement System</p>
				</div>
				<div class="right">
					<div class="time">{{ nowDate }}</div>
					<div class="info"
						 v-if="isLogin">
						<img class="avatar"
							 src="@/assets/images/avatar.png"
							 alt="">
						<div class="user-info">
							<p class="w100">{{ userInfo.sname }}</p>
							<p>
								<span>您好！</span>
								<span @click="userQuit"
									  class="logOut">退出</span>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="content">
				<template v-if="this.step == 1">
					<div class="enter-system">
						<el-button type="text"
								   @click="enterSystem">
							<svg-icon icon-class="enter-sys"></svg-icon>
							进入系统
						</el-button>
					</div>
					<div class="content-box">
						<div class="outside"
							 @click="changeStep(2)">
							<div class="img-box">
								<div class="title">项目进度与投资>></div>
								<div class="box box1">
									<div class="subtitle">本年度项目总投资
										<el-tooltip effect="light"
													placement="bottom-start">
											<template slot="content">
												<div v-html="tips1"></div>
											</template>
											<svg-icon icon-class="wenhao"></svg-icon>
										</el-tooltip>
									</div>
									<!-- <img src="@/assets/images/pie.png"
										 alt=""> -->
									<div id="pie"
										 class="echart-box"></div>
								</div>
							</div>
						</div>
						<div class="outside"
							 @click="changeStep(3)">
							<div class="img-box">
								<div class="title">项目管理与统计>></div>
								<div class="box box2">
									<div class="subtitle">本年度县区项目数</div>
									<!-- <img src="@/assets/images/bar.png"
										 alt=""> -->
									<div id="bar"
										 class="echart-box"></div>
								</div>
							</div>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="return">
						<el-button type="text"
								   @click="returnUp">
							<svg-icon icon-class="fanhui"></svg-icon>
							返回上一级
						</el-button>
						<div style="padding-right: 24px">
							<el-button type="text"
									   @click="enterSystem">
								<svg-icon icon-class="enter-sys"></svg-icon>
								进入系统
							</el-button>
						</div>
					</div>
					<div class="jump-box"
						 v-if="this.step == 2">
						<div class="btn-box"
							 v-for="item in buttonGroup1"
							 :key="item.id"
							 @click="jump(item.jumpUrl, item)">
							<div class="imgs-box">
								<div class="text-center">
									<svg-icon :icon-class="item.icon"></svg-icon>
									{{ item.iconText }}
								</div>
							</div>
						</div>
					</div>
					<div class="jump-box"
						 v-if="this.step == 3">
						<div class="btn-box"
							 v-for="item in buttonGroup2"
							 :key="item.id"
							 @click="jump(item.jumpUrl)">
							<div class="imgs-box">
								<div class="text-center">
									<svg-icon :icon-class="item.icon"></svg-icon>
									{{ item.iconText }}
								</div>
							</div>
						</div>
					</div>
					<div class="jump-box"
						 v-if="this.step == 4">
						<div class="btn-box"
							 v-for="item in buttonGroup3"
							 :key="item.id"
							 @click="jump(item.jumpUrl)">
							<div class="imgs-box sp-img">
								<div class="text-center">
									<svg-icon :icon-class="item.icon"></svg-icon>
									{{ item.iconText }}
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
	getContainerParams,
	statisticsProjectScheduleAndInvestment,
	selectProjectStatistics,
	statisticsProjectScheduleAndInvestmentForDeclare,
} from 'api/home/homeIndex'
import * as echarts from 'echarts'
export default {
	name: '',
	components: {},
	data() {
		return {
			nowDate: '',
			step: 1,
			isLogin: true,
			BarObject: null,
			activeRouteList: [],
		}
	},
	created() {
		this.nowDate = this.$utils.toDateString(new Date(), 'yyyy年MM月dd日')
		this.getContainerParams()
		if (this.routeParams.type) {
			this.changeStep(this.routeParams.type)
		} else {
			// 饼状图
			this.getPieChartsData()
			// 柱状图
			this.getBarChartsData()
		}
		this.activeRouteList = this.activeRoutes.filter((x) => !x.isHidden)
	},
	methods: {
		...mapActions('user', ['logOut']),

		jumpMap() {
			return
			let tenantId = this.$getSessionItem('tenantId')
			let limitId = this.$getSessionItem('limitId')
			let token = this.$getSessionItem('token')
			window.open(
				`${window.location.origin}/DxAuditAdmin/#/projectMapOnly?token=${token}&limitId=${limitId}&tenantId=${tenantId}`
			)
		},

		// 饼状图
		getPieChartsData() {
			statisticsProjectScheduleAndInvestmentForDeclare({}).then((res) => {
				let _item = document.getElementById('pie')
				let _Chart = echarts.init(_item)
				this.initPieCharts(_Chart, res.data)
			})
		},

		// 柱状图
		getBarChartsData() {
			if (this.BarObject) {
				this.BarObject.dispose()
			}
			selectProjectStatistics({}).then((res) => {
				let _item = document.getElementById('bar')
				this.BarObject = echarts.init(_item)
				let areaData = []
				res.data.map((x) => {
					areaData.push(x.sname)
				})
				this.initBarCharts(res.data, areaData)
			})
		},

		// 渲染柱状图
		initBarCharts(resData, areaData) {
			let that = this
			let activeOption = {
				tooltip: {
					trigger: 'axis',
					formatter: function (params) {
						let obj = params[0]
						return `${obj.marker}${obj.axisValue}&nbsp;&nbsp;&nbsp;${obj.value} 个`
					},
				},
				label: {
					show: true,
					position: 'top',
					formatter: function (params) {
						return `${params.value} 个`
					},
				},
				grid: {
					left: '15%',
				},
				xAxis: [
					{
						type: 'category',
						data: areaData,
						axisPointer: {
							type: 'shadow',
						},
						axisLabel: {
							interval: 0,
						},
						axisTick: {
							show: false,
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						axisLabel: {
							formatter: function (params) {
								return `${params} 个`
							},
						},
						minInterval: 1,
					},
				],
				series: [
					{
						type: 'bar',
						data: [
							{
								value: resData[0].num,
								itemStyle: {
									color: '#40a6ff',
								},
							},
							{
								value: resData[1].num,
								itemStyle: {
									color: '#27a97d',
								},
							},
							{
								value: resData[2].num,
								itemStyle: {
									color: '#fed47d',
								},
							},
						],
					},
				],
			}

			this.BarObject.setOption(activeOption)
			window.addEventListener('resize', function () {
				that.BarObject.resize()
			})
		},

		// 渲染饼状图
		initPieCharts(echart, resData) {
			let totalNum = parseFloat(resData.totalInvestment)
			let comValue = parseFloat(resData.completedInvestment)
			let noComplete = totalNum - comValue
			let percent = 0
			let percentMsg = ''
			if (totalNum == 0) {
				percentMsg = '已完成' + comValue + '万元'
			} else {
				percent = this.$utils.divide(comValue, totalNum) || 1
				percent = comValue ? percent : 0
				percent = (percent * 100).toFixed(2)
				percentMsg = '已完成' + percent + '%'
			}
			let noPercent = 0
			if (percent === 0) {
				percent = percent.toFixed(2)
			} else {
				noPercent = 100 - percent
			}
			noPercent = noPercent.toFixed(2)
			let data = [
				{
					name: percentMsg,
					value: comValue || '',
					label: {
						show: true,
						position: 'inside',
					},
					itemStyle: {
						color: '#62daab',
					},
				},
				{
					name: '剩余' + noPercent + '%',
					value: noComplete || '',
					label: {
						show: true,
						position: 'inside',
					},
					itemStyle: {
						color: '#cccccc',
					},
				},
				{
					name: '项目总投资' + totalNum + '万元',
					value: 0,
					label: {
						width: 80,
						overflow: 'breakAll',
						show: true,
						position: 'center',
					},
				},
			]

			let activeOption = {
				tooltip: {
					trigger: 'item',
					formatter: function (params) {
						if (params.value == 0) {
							return
						}
						return `${params.name}`
					},
				},
				label: {
					fontSize: '16',
				},
				series: [
					{
						type: 'pie',
						center: ['50%', '45%'],
						radius: ['50%', '80%'],
						data: data,
					},
				],
			}
			echart.setOption(activeOption)
			window.addEventListener('resize', function () {
				echart.resize()
			})
		},

		// 获取后台时间
		getContainerParams() {
			getContainerParams({}).then((res) => {
				let date = new Date(res.data.currentDate)
				let hour = date.getHours()
				hour = hour < 10 ? '0' + hour : hour
				let minutes = date.getMinutes()
				minutes = minutes < 10 ? '0' + minutes : minutes
				let week = date.getDay()
				let msg = ''
				switch (week) {
					case 0:
						msg = '星期天'
						break
					case 1:
						msg = '星期一'
						break
					case 2:
						msg = '星期二'
						break
					case 3:
						msg = '星期三'
						break
					case 4:
						msg = '星期四'
						break
					case 5:
						msg = '星期五'
						break
					case 6:
						msg = '星期六'
						break
				}
				this.nowDate =
					this.nowDate + msg + '     ' + hour + ':' + minutes
			})
		},

		// 退出
		userQuit() {
			this.logOut().then(() => {
				this.$router.push({
					path: '/login',
				})

				setTimeout(() => {
					this.$message.success('退出成功!')
				}, 500)
			})
		},

		// 第一步选择
		changeStep(data) {
			this.step = data
		},

		// 跳转
		jump(url, data) {
			if (data && data.id == '102') {
				this.step = 4
				return
			}
			let list = this.activeRouteList.filter((x) => x.path == url)
			if (list.length == 0) {
				this.$message.warning(
					'该主体身份暂无该菜单功能查看权限，请返回上一级!'
				)
				return
			}
			this.$router.push({
				path: url,
			})
		},

		returnUp() {
			if (this.step == 4) {
				this.step = 2
				return
			}
			this.step = 1
			// 饼状图
			this.getPieChartsData()
			// 柱状图
			this.getBarChartsData()
		},

		// 进入系统
		enterSystem() {
			this.$router.push({
				path: '/projectManagement',
			})
		},
	},
	computed: {
		...mapState('user', {
			userInfo: (state) => state.userInfo,
		}),

		...mapState('menu', {
			activeRoutes: (state) => state.activeRoutes,
			routeList: (state) => state.routeList,
		}),

		buttonGroup1() {
			let arr = [
				{
					id: '103',
					jumpUrl: '/InvestmentManagement',
					icon: 'Index3',
					iconText: '投资管理 >>',
				},
				{
					id: '101',
					jumpUrl: '/VisualProcess',
					icon: 'Index1',
					iconText: '形象进度 >>',
				},
				{
					id: '102',
					jumpUrl: '/projectMap',
					icon: 'Index2',
					iconText: '智慧工地 >>',
				},
			]
			return arr
		},

		buttonGroup2() {
			let arr = [
				{
					id: '201',
					jumpUrl: '/BasicData',
					icon: 'Index4',
					iconText: '基础资料 >>',
				},
				{
					id: '202',
					jumpUrl: '/Tripartite',
					icon: 'Index5',
					iconText: '三方巡查 >>',
				},
				{
					id: '203',
					jumpUrl: '/AuditReport',
					icon: 'Index6',
					iconText: '审计统计 >>',
				},
			]
			return arr
		},

		buttonGroup3() {
			let arr = [
				{
					id: '301',
					jumpUrl: '/projectMap',
					icon: 'Index7',
					iconText: '智慧地图 >>',
				},
				{
					id: '302',
					jumpUrl: '/wisdom-site',
					icon: 'Index8',
					iconText: '工地管理 >>',
				},
				{
					id: '303',
					jumpUrl: '/planMap',
					icon: 'Index9',
					iconText: '规划图 >>',
				},
			]
			return arr
		},

		// 获取路由参数
		routeParams() {
			return this.$route.params
		},

		tips1() {
			let msg = `本年度项目：
				<br/>
				有计划开竣工时间，且时间范围在本年度的，无实际竣工时间都纳入本年度项目统计。
				<br/>
				有计划开竣工时间，且实际竣工时间为本年度的，本年纳入统计，第二年不统计。
				<br />
				本年度项目总投资：统计本年度项目本年度的（工程费用+土地费用+拆迁费用+其他费用）计划完成产值之和。
				<br />
				完成固定投资：所有项目申报月份为本年度的（工程费用+土地费用+拆迁费用+其他费用）申报完成产值之和。
				<br />
				未完成投资=项目总投资-已完成投资。
			`
			return msg
		},
	},
}
</script>
<style lang='less' scoped>
.select-area-layout {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #77b9fa;
	padding: 35px 130px;
	.select-box {
		width: 100%;
		height: 100%;
		border-radius: 25px;
		background-image: url('../../assets/images/selectBack.png');
		background-size: 100%;
		background-position: center;
		.header {
			height: 135px;
			border-top-left-radius: 25px;
			border-top-right-radius: 25px;
			background-color: rgba(0, 64, 240, 0.5);
			display: flex;
			justify-content: space-between;
		}
		.left {
			height: 100%;
			color: #fff;
			padding: 28px 0px 0 47px;
			p:first-of-type {
				font-size: 36px;
				margin-bottom: 5px;
			}
			p:last-of-type {
				font-size: 21px;
			}
		}
		.right {
			display: flex;
			color: #fff;
			font-weight: 500;
			align-items: center;
			padding-right: 40px;
			.time {
				width: 160px;
				line-height: 30px;
				font-size: 21px;
				margin-right: 15px;
			}
			.info {
				display: flex;
			}
			.avatar {
				border-radius: 50%;
				height: 90px;
				width: 90px;
			}
			.user-info {
				font-size: 25px;
				margin-left: 15px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				line-height: 35px;
			}
			.logOut {
				cursor: pointer;
			}
			.logOut:hover {
				color: rgb(240, 83, 83);
			}
		}
		.content {
			height: calc(100% - 100px);
			border-bottom-left-radius: 25px;
			border-bottom-right-radius: 25px;
			background-color: rgba(88, 163, 247, 0.1);
		}
		.enter-system {
			height: 17%;
			width: calc(80% + 20px);
			margin: 0 auto;
			padding-right: 30px;
			padding-bottom: 10px;
			display: flex;
			flex-direction: row-reverse;
			align-items: flex-end;

			/deep/ .el-button--text {
				color: #fff;
				font-size: 24px;
			}
		}
		.content-box {
			height: 83%;
			display: flex;
			justify-content: center;
			// align-items: center;
			.outside {
				width: 40%;
				height: 78%;
				border-radius: 10px;
				padding: 10px;
				cursor: pointer;
				background-color: rgba(255, 255, 255, 0.4);
			}
			.outside + .outside {
				margin-left: 20px;
			}
			.img-box {
				width: 100%;
				height: 100%;
				border-radius: 10px;
				background-color: #fff;
				.title {
					height: 110px;
					line-height: 110px;
					text-align: center;
					color: #1c81c9;
					font-size: 36px;
					font-weight: 700;
					font-family: '微软雅黑 Bold', '微软雅黑';
				}
				.subtitle {
					font-size: 20px;
					font-weight: bold;
				}
				.box {
					height: calc(~'100% - 110px');
					text-align: center;
				}
				.box1 {
					img {
						width: 80%;
						height: 90%;
					}
				}
				.box2 {
					img {
						height: 90%;
						width: 85%;
					}
				}
			}
		}
		@media screen and (max-width: 1366px) {
			.title {
				height: 50px !important;
				line-height: 50px !important;
				font-size: 20px !important;
			}
			.box {
				height: calc(~'100% - 50px') !important;
			}
			.box1 {
				img {
					width: 80% !important;
					height: 90% !important;
				}
			}
			.subtitle {
				font-size: 16px !important;
				font-weight: bold;
			}
			.box2 {
				img {
					width: 92% !important;
					height: 95% !important;
				}
			}
			.imgs-box {
				height: 120px !important;
				div {
					font-size: 20px !important;
				}
				svg {
					font-size: 30px !important;
				}
			}
		}
		.jump-box {
			width: 70%;
			// height: 80%;
			margin: 0 auto;
			display: flex;
			padding-top: 60px;
		}
		.return {
			height: 25%;
			width: 70%;
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			justify-content: flex-end;
			margin: 0 auto;
			.el-button--text {
				font-size: 24px !important;
				color: #fff !important;
			}
		}
		.btn-box {
			flex: 1;
			cursor: pointer;
			img {
				width: 100%;
			}
		}
		.btn-box + .btn-box {
			margin-left: 40px;
		}
		.w50 {
			width: 45% !important;
		}
		.imgs-box {
			padding: 10px;
			border-radius: 10px;
			background-color: rgba(255, 255, 255, 0.4);
			height: 140px;
			div {
				height: 100%;
				border-radius: 10px;
				background-color: #33cc99;
				color: #fff;
				font-size: 28px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			svg {
				font-size: 40px;
				margin-right: 15px;
			}
		}
		.sp-img {
			div {
				background-color: #0078f0 !important;
			}
		}
	}
	.echart-box {
		width: 100%;
		height: 100%;
	}
}
</style>